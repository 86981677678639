'use strict';

angular.module('gatewayApp')
        .factory('ChartCommons', ChartCommons);

ChartCommons.$inject = ['$filter'];
function ChartCommons($filter) {
    return {
        generateChartConfig: function (bindToId, bestSalesChart) {
            var x = [];
            x.push('x');
            x.push.apply(x, bestSalesChart.xAxe.x);
            var y = [];
            var allSeries = bestSalesChart.yAxe;
            for (var key in allSeries) {
                if (allSeries.hasOwnProperty(key)) {
                    var seriesWithNameAndValues = [];
                    seriesWithNameAndValues.push(key);
                    var seriesValues = allSeries[key];
                    var extractedValues = seriesValues.map(function (obj) {
                        return obj.amount;
                    });
                    seriesWithNameAndValues.push.apply(
                            seriesWithNameAndValues,
                            extractedValues);
                    y.push(seriesWithNameAndValues);
                }
            }
            var chartColumns = [];
            chartColumns.push(x);
            for (var int = 0; int < y.length; int++) {
                chartColumns.push(y[int]);
            }
            return {
                bindto: bindToId,
                data: {
                    labels: true,
                    x: 'x',
                    columns: chartColumns,
                    type: 'line'
                },
                subchart: {
                    show: true
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: '%Y-%m-%d'
                        }
                    }
                }
            };
        },
        generateChartSeries: function (chart) {
            var chartconfig = {};
            var data = [];
            var columns = [];
            var valuesOfKey;
            var salesOnItem = chart.yAxe;
            var average = chart.average;
            var xAxe = chart.xAxe;
            //prepare columns
            var columnNames = xAxe.x;
            for (var col in columnNames) {
                columns[col] = {
                    id: 'amount'.concat(col),
                    name: columnNames[col],
                    field: 'amount' + col
                };
            }
            //prepare data
            for (var key in salesOnItem) {
                if (salesOnItem.hasOwnProperty(key)) {
                    valuesOfKey = salesOnItem[key];
                    var tempValue = {'name': key};
                    var total = 0;
                    for (var value in valuesOfKey) {
                        if(valuesOfKey.hasOwnProperty(value)){
                            var cell = {};
                            cell['amount' ] = valuesOfKey[value].amount;
                            cell['availability' ] = valuesOfKey[value].availability;
                            cell['currentStockAmount' ] = valuesOfKey[value].currentStockAmount;
                            total += valuesOfKey[value].amount;
                            tempValue['cell' + value] = cell;
                        }
                    }
                    tempValue['average'] = average[key];
                    tempValue['total'] = total;
                    data.push(tempValue);
                }
            }
            chartconfig.data = data;
            chartconfig.columns = columns;
            return chartconfig;
        },
        generateChartAverageColumnsConfig: function (chart) {
            var allSeries = chart.yAxe;
            var column;
            var chartColumnConfig = [];
            for (var key in allSeries) {
              if(allSeries.hasOwnProperty(key)){
                column = {field: 'name',
                    displayName: 'Name',
                    pinnedLeft: true,
                    width: '75',
                    headerTooltip: 'Artikel-Nr.'};
                chartColumnConfig.push(column);
                column = {field: 'average.name',
                        displayName: 'Beschreibung',
                        pinnedLeft: true,
                        width: '125',
                        headerTooltip: 'Beschreibung'};
                chartColumnConfig.push(column);
                column = {field: 'average.totalAmount',
                    displayName: 'T',
                    pinnedLeft: true,
                    width: 50,
                    headerTooltip: 'Gesamtverkauf für das Zeitintervall, das über den Kalender ausgewählt wurde'};
                chartColumnConfig.push(column);
                column = {field: 'average.averageOtto',
                    displayName: 'Ø',
                    pinnedLeft: true,
                    cellFilter: 'number:2',
                    width: 50,
                    headerTooltip: 'Durchschnitt = TO / #TO'};
                chartColumnConfig.push(column);
                column = {name: 'average.numberOfDaysOtto',
                    displayName: '#TO',
                    pinnedLeft: true,
                    width: 50,
                    headerTooltip: 'Anzahl der Tage, an denen das Produkt bei Otto online war'};
                chartColumnConfig.push(column);
                column = {name: 'average.amount',
                    displayName: 'TO',
                    pinnedLeft: true,
                    width: 50,
                    headerTooltip: 'Gesamtverkauf nur an den Tagen, an denen das Produkt bei Otto online war'};
                chartColumnConfig.push(column);

                break;
              }
            }
            return chartColumnConfig;

        },
        generateChartColumnsConfig: function (chart) {
            var allSeries = chart.yAxe;
            var serie, keyName, dateAsString, column;
            var chartColumnConfig = [];
            for (var key in allSeries) {
              if(allSeries.hasOwnProperty(key)){
                serie = allSeries[key];
                for (var values in serie) {
                  if(serie.hasOwnProperty(values)){
                    column = {};
                    keyName = 'cell' + values;
                    dateAsString = serie[values].date;
                    column.field = keyName;
                    column.displayName = $filter('date')(dateAsString, 'dd.MM');
                    column.cellTemplate = '<div title="In Stock vorhanden {{row.entity[\'' + keyName + '\'].currentStockAmount}}">{{row.entity[\'' + keyName + '\'].amount}}</div>';
                    column.name = keyName + '.amount';
                    column.cellClass = function (grid, row, col) {
                        if (grid.getCellValue(row, col).availability === 'NEW_IN_STOCK_AND_1_SALE_OTTO') {
                            return 'bg';
                        } else if (grid.getCellValue(row, col).availability === 'FIRST_SALE_ON_OTTO') {
                            return 'b';
                        } else if (grid.getCellValue(row, col).availability === 'CHANGE_TO_SOLD') {
                            return 'r';
                        } else if (grid.getCellValue(row, col).availability === 'NEW_IN_STOCK') {
                            return 'g';
                        }
                        return '';
                    };
                    column.enableColumnMenu = false;
                    column.width = '65';
                    chartColumnConfig.push(column);
                  }
                }
                break;
              }
            }
            var averageChart = this.generateChartAverageColumnsConfig(chart);
            averageChart = averageChart.concat(chartColumnConfig);
            return averageChart;
        },
        generateStockContinuityChartColumnDefs: function (chart) {
            var chartColumnConfig, averageChart, column;
            chartColumnConfig = [];
            column = {field: 'totalNrOfOnlineDays',
                displayName: '#Tage Online',
                pinnedLeft: true,
                width: 100};
            chartColumnConfig.push(column);
            column = {field: 'numberOfIntervals',
                displayName: '#Int',
                pinnedLeft: true,
                width: 50};
            chartColumnConfig.push(column);

            averageChart = this.generateChartAverageColumnsConfig(chart);
            averageChart = averageChart.concat(chartColumnConfig);
            return averageChart;
        },
        generateStockContinuityChartSeries: function (chart) {
            var allSeries, key, serie, chartSerie;
            chartSerie = [];
            allSeries = chart.yAxe;
            var averages = chart.average;
            for (key in allSeries) {
                if (allSeries.hasOwnProperty(key)) {
                    serie = allSeries[key];
                    var tempValue = {'name': key};
                    tempValue['average'] = averages[key];
                    tempValue['totalNrOfOnlineDays'] = serie.totalNrOfOnlineDays;
                    tempValue['numberOfIntervals'] = serie.numberOfIntervals;
                    chartSerie.push(tempValue);
                }
            }
            return chartSerie;
        }
    };
}
