(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-edi', {
            parent: 'entity',
            url: '/invoice-edi',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.invoiceEdi.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-edi/invoice-edis.html',
                    controller: 'InvoiceEdiController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceEdi');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('invoice-edi-detail', {
            parent: 'invoice-edi',
            url: '/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.invoiceEdi.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-detail.html',
                    controller: 'InvoiceEdiDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceEdi');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InvoiceEdi', function($stateParams, InvoiceEdi) {
                    return InvoiceEdi.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'invoice-edi',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('invoice-edi-detail.edit', {
            parent: 'invoice-edi-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-dialog.html',
                    controller: 'InvoiceEdiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceEdi', function(InvoiceEdi) {
                            return InvoiceEdi.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-edi.new', {
            parent: 'invoice-edi',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-dialog.html',
                    controller: 'InvoiceEdiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                createdOn: null,
                                content: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('invoice-edi', null, { reload: 'invoice-edi' });
                }, function() {
                    $state.go('invoice-edi');
                });
            }]
        })
        .state('invoice-edi.edit', {
            parent: 'invoice-edi',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-dialog.html',
                    controller: 'InvoiceEdiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceEdi', function(InvoiceEdi) {
                            return InvoiceEdi.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-edi', null, { reload: 'invoice-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-edi.delete', {
            parent: 'invoice-edi',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-delete-dialog.html',
                    controller: 'InvoiceEdiDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InvoiceEdi', function(InvoiceEdi) {
                            return InvoiceEdi.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-edi', null, { reload: 'invoice-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-edi.content', {
            parent: 'entity',
            url: '/customer/{customerId}/invoice-info/{invoiceInfoId}/edi-content',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-edi/invoice-edi-content.html',
                    controller: 'InvoiceEdiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceInfo', function(InvoiceInfo) {
                            return InvoiceInfo.getEdiContent({id : $stateParams.invoiceInfoId}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('invoiceEdi');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-edi', null, { reload: 'invoice-edi' });
                }, function() {
                    $state.go('invoice-position', {customerId: $stateParams.customerId, invoiceInfoId:$stateParams.invoiceInfoId});
                });
            }]

        });
    }

})();
