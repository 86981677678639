(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExcelFileDeleteController',ExcelFileDeleteController);

    ExcelFileDeleteController.$inject = ['$uibModalInstance', 'entity', 'ExcelFile'];

    function ExcelFileDeleteController($uibModalInstance, entity, ExcelFile) {
        var vm = this;

        vm.excelFile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExcelFile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
