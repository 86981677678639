(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcTechnicsGroupDetailController', TechnicsGroupDetailController);

    TechnicsGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity'];

    function TechnicsGroupDetailController($scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.technicsGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:technicsGroupUpdate', function(event, result) {
            vm.technicsGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
