(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcPictureDeleteSelectionController',PictureDeleteSelectionController);

    PictureDeleteSelectionController.$inject = ['$uibModalInstance', '$stateParams','pcProduct', '$state', 'pcPicture'];

    function PictureDeleteSelectionController($uibModalInstance, $stateParams,Product,$state,Picture) {
        var vm = this;

        vm.product = $state.params.product; 
        vm.picturesToDelete = $state.params.picturesToDelete;
                        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
               
      
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    
        function confirmDelete() {
            Picture.delete_selection({
                pictureIdsToDelete : get_pictureIdsToDelete(vm.picturesToDelete)}, onSuccess);
        }
        function onSuccess(){
            $uibModalInstance.close(true);
        }
        
        
        function get_pictureIdsToDelete(picturesToDelete){
            var pictureIdsToDelete = [];
            for(var i=0; i<picturesToDelete.length; i++){
                pictureIdsToDelete[i] = picturesToDelete[i].id;
            }
            return pictureIdsToDelete;
        }    
    }
})();
