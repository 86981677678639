(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcProductPreviewController', ProductPreviewController);

    ProductPreviewController.$inject = ['$scope','$rootScope', '$stateParams', '$uibModalInstance', '$document', 'entity', 'entityProduct'];


    function ProductPreviewController($scope, $rootScope, $stateParams, $uibModalInstance, $document, entity, entityProduct) {
        
        var vm = this;
        vm.product = entityProduct;
        
        $uibModalInstance.rendered.then(function(){
            var iframe = $document[0].getElementById('myFrame');
            iframe = iframe.contentWindow || ( iframe.contentDocument.document || iframe.contentDocument);
    
            iframe.document.open();
            iframe.document.write(entity.html);
            iframe.document.close();
            
        });
        
    }
})();
