(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrderPositionDetailController', OrderPositionDetailController);

    OrderPositionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OrderPosition', 'PurchaseOrder'];

    function OrderPositionDetailController($scope, $rootScope, $stateParams, previousState, entity, OrderPosition, PurchaseOrder) {
        var vm = this;

        vm.orderPosition = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:orderPositionUpdate', function(event, result) {
            vm.orderPosition = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
