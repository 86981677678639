'use strict';

angular.module('gatewayApp')
            .factory('ProductGroupAndSupplierDD', ProductGroupAndSupplierDD);

ProductGroupAndSupplierDD.$inject = ['SalesOnItem', '$q'];

function ProductGroupAndSupplierDD(SalesOnItem, $q){
    return {
        getProductGroupDD: function(){

        },
        getSupplierDD: function(){
            var getSupplierID = SalesOnItem.getSupplierID.query({});
            return $q(getSupplierID.$promise.then(function () {
                var supplierOptions = getSupplierID;
                supplierOptions.unshift({id: 0, company: 'keine Einschränkung'});
                return supplierOptions;
            }));
        }
    };
}