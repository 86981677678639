(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcPictureController', PictureController);

    PictureController.$inject = ['$scope', '$state', '$stateParams', 'pcPicture', 'pcProduct', 'AlertService'];

    function PictureController ($scope, $state, $stateParams, Picture, Product, AlertService) {
        var vm = this;
        
        vm.pictures = [];
        vm.productId = $stateParams.productId;
        vm.product = Product.get({id: $stateParams.productId});
        vm.picturesToDelete = [];

        vm.checkAll = checkAll;
        vm.uncheckAll = uncheckAll;
        vm.delete_picture_selection = delete_picture_selection;        
        
        loadAll();


        function loadAll() {
            Picture.query({ productId:vm.productId }, function(result) {
                vm.pictures = result;
            });
        }
        
        function uncheckAll(){
            vm.picturesToDelete = [];
        }
        
        function checkAll(){
            vm.picturesToDelete = vm.pictures;
        }


        function delete_picture_selection(){
            
            if(vm.picturesToDelete.length === 0){
                AlertService.error("productComposerApp.picture.delete_selection.message");
            }
            else{
                $state.go('composer.picture.delete_selection', {product: vm.product,
                    picturesToDelete: vm.picturesToDelete});
            }
        }
    }
})();
