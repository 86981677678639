(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoiceFileDialogController', InvoiceFileDialogController);

    InvoiceFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'InvoiceFile', 'InvoiceInfo'];

    function InvoiceFileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, InvoiceFile, InvoiceInfo) {
        var vm = this;

        vm.invoiceFile = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.invoiceinfos = InvoiceInfo.query({filter: 'invoicefile-is-null'});
        $q.all([vm.invoiceFile.$promise, vm.invoiceinfos.$promise]).then(function() {
            if (!vm.invoiceFile.invoiceInfoId) {
                return $q.reject();
            }
            return InvoiceInfo.get({id : vm.invoiceFile.invoiceInfoId}).$promise;
        }).then(function(invoiceInfo) {
            vm.invoiceinfos.push(invoiceInfo);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.invoiceFile.id !== null) {
                InvoiceFile.update(vm.invoiceFile, onSaveSuccess, onSaveError);
            } else {
                InvoiceFile.save(vm.invoiceFile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:invoiceFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setCsv = function ($file, invoiceFile) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        invoiceFile.csv = base64Data;
                        invoiceFile.csvContentType = $file.type;
                    });
                });
            }
        };

        vm.setInvoice = function ($file, invoiceFile) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        invoiceFile.invoice = base64Data;
                        invoiceFile.invoiceContentType = $file.type;
                    });
                });
            }
        };

    }
})();
