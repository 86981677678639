(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoiceFileController', InvoiceFileController);

    InvoiceFileController.$inject = ['DataUtils', 'InvoiceFile'];

    function InvoiceFileController(DataUtils, InvoiceFile) {

        var vm = this;

        vm.invoiceFiles = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            InvoiceFile.query(function(result) {
                vm.invoiceFiles = result;
                vm.searchQuery = null;
            });
        }
    }
})();
