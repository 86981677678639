(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('EdiInvoiceController', EdiInvoiceController);

    EdiInvoiceController.$inject = ['entity', 'InvoiceInfo', '$uibModalInstance'];

    function EdiInvoiceController(entity, InvoiceInfo, $uibModalInstance) {

        var vm = this;
        vm.invoiceInfo = entity;
        vm.clear = clear;
        vm.sendInvoic = sendInvoic;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendInvoic(invoiceInfoId){
            InvoiceInfo.generateAndUploadInvoic({id : invoiceInfoId},
                    function () {
                        $uibModalInstance.close(true);
                    });
        }
    }
})();
