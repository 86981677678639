(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductGroupController', ProductGroupController);

    ProductGroupController.$inject = ['ProductGroup'];

    function ProductGroupController(ProductGroup) {

        var vm = this;

        vm.productGroups = [];

        loadAll();

        function loadAll() {
            ProductGroup.query(function(result) {
                vm.productGroups = result;
                vm.searchQuery = null;
            });
        }
    }
})();
