(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExcelFileDetailController', ExcelFileDetailController);

    ExcelFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ExcelFile', 'FileBlob', 'ExcelContent'];

    function ExcelFileDetailController($scope, $rootScope, $stateParams, previousState, entity, ExcelFile, FileBlob, ExcelContent) {
        var vm = this;

        vm.excelFile = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:excelFileUpdate', function(event, result) {
            vm.excelFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
