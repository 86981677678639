(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TrackingFileBlobDialogController', TrackingFileBlobDialogController);

    TrackingFileBlobDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'TrackingFileBlob'];

    function TrackingFileBlobDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, TrackingFileBlob) {
        var vm = this;

        vm.trackingFileBlob = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.importTrackingFile = importTrackingFile;
        vm.trackingFileId = '';

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function importTrackingFile () {
            vm.isSaving = true;
            TrackingFileBlob.importTrackingFile({trackingFileId: vm.trackingFileId}, vm.trackingFileBlob,
                 onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:trackingFileBlobUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setFileContent = function ($file, trackingFileBlob) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        trackingFileBlob.fileContent = base64Data;
                        trackingFileBlob.fileContentContentType = $file.type;
                    });
                });
            }
        };

        vm.previewTrackingNr = function(){
            vm.trackingNumberFile = {
                fileName: 'just a file',
                trackingFileBlob: vm.trackingFileBlob
            };
            TrackingFileBlob.previewTrackingNr(vm.trackingNumberFile, onPreviewSuccess, onPreviewError);
        };

        function onPreviewSuccess(result) {
            vm.trackingFileId = result.trackingFileId;
            vm.trackingNrWithOrders = result.ordersWithTrackingNr;
            vm.trackingNrWithoutOrders = result.trackingNrWithoutOrders;
        }

        function onPreviewError(){
            vm.isSaving = false;
        }
    }
})();
