(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('Product2PriceDetailController', Product2PriceDetailController);

    Product2PriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Product2Price', 'Product'];

    function Product2PriceDetailController($scope, $rootScope, $stateParams, previousState, entity, Product2Price, Product) {
        var vm = this;

        vm.product2Price = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:product2PriceUpdate', function(event, result) {
            vm.product2Price = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
