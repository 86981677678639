(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-info', {
            parent: 'entity',
            url: '/customer/{customerId}/invoice-info',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.invoiceInfo.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-info/invoice-infos.html',
                    controller: 'InvoiceInfoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceInfo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('invoice-info.new', {
            parent: 'invoice-info',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                    controller: 'InvoiceInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        customer: ['Customer', function(Customer) {
                            return Customer.get({id: $stateParams.customerId}).$promise;
                        }],
                        invoiceFile: function(){
                            return {csv: null,
                                csvContentType: null,
                                invoice: null,
                                invoiceContentType: null,
                                id: null};
                        },
                        entity: function () {
                            return {
                                invoiceNr: null,
                                invoiceDate: null,
                                uploadDate: null,
                                isInvoicePositionsImported: false,
                                importDate: null,
                                isInvoiceSend: false,
                                sendDate: null,
                                csv: null,
                                csvContentType: null,
                                invoice: null,
                                invoiceContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('invoice-info', null, { reload: 'invoice-info' });
                }, function() {
                    $state.go('invoice-info');
                });
            }]
        })
        .state('invoice-info.edit', {
            parent: 'invoice-info',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                    controller: 'InvoiceInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        customer: ['Customer', function(Customer) {
                            return Customer.get({id: $stateParams.customerId}).$promise;
                        }],
                        invoiceFile: ['InvoiceInfo', function(InvoiceInfo){
                            return InvoiceInfo.getFile4Invoice({id: $stateParams.id}).$promise;
                        }],
                        entity: ['InvoiceInfo', function(InvoiceInfo) {
                            return InvoiceInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-info', null, { reload: 'invoice-info' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-info.delete', {
            parent: 'invoice-info',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-info/invoice-info-delete-dialog.html',
                    controller: 'InvoiceInfoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InvoiceInfo', function(InvoiceInfo) {
                            return InvoiceInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-info', null, { reload: 'invoice-info' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-info.preview', {
            parent: 'invoice-info',
            url: '/{id}/preview',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'AlertService', 'InvoiceInfo', function($stateParams, $state, $uibModal, AlertService, InvoiceInfo){

                InvoiceInfo.getPositionToImport({id : $stateParams.id}).$promise
                .then(function(){
                    $uibModal.open({
                        templateUrl: 'app/entities/invoice-info/invoice-info-preview-detail.html',
                        controller: 'InvoiceInfoPreviewDetailController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['InvoiceInfo', function(InvoiceInfo) {
                                return InvoiceInfo.getPositionToImport({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('invoice-info', null, {reload: 'invoice-info'});
                    }, function() {
                        $state.go('^');
                    });

                }, function(response){
                    AlertService.error(response.data.detail);
                    $state.go('invoice-info', null, {reload: 'invoice-info'});
                });
            }]
        })
        .state('invoice-info.edi-invoice', {
            parent: 'invoice-info',
            url: '/{id}/edi-invoice',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-info/invoice-edi-preview.html',
                    controller: 'EdiInvoiceController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InvoiceInfo', function(InvoiceInfo) {
                            return InvoiceInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-info', null, {reload: 'invoice-info'});
                }, function() {
                    $state.go('invoice-position');
                });
            }]
        });
    }

})();
