(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcTechnicsKeyController', TechnicsKeyController);

    TechnicsKeyController.$inject = ['$scope', '$state', '$stateParams', 'pcTechnicsKey', 'pcTechnicsGroup'];

    function TechnicsKeyController ($scope, $state, $stateParams, TechnicsKey, TechnicsGroup) {
        var vm = this;

        vm.technicsGroupId = $stateParams.technicsGroupId;
        vm.technicsGroup = TechnicsGroup.get({id: $stateParams.technicsGroupId});
        vm.technicsKeys = [];

        loadAll();

        function loadAll() {
            TechnicsKey.query({technicsGroupId: vm.technicsGroupId}, function(result) {
                vm.technicsKeys = result;
                vm.searchQuery = null;
            });
        }
    }
})();
