(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OttoProducts2ProductsDeleteController',OttoProducts2ProductsDeleteController);

    OttoProducts2ProductsDeleteController.$inject = ['$uibModalInstance', 'entity', 'OttoProducts2Products'];

    function OttoProducts2ProductsDeleteController($uibModalInstance, entity, OttoProducts2Products) {
        var vm = this;

        vm.ottoProducts2Products = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OttoProducts2Products.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
