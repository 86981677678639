(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoiceInfoController', InvoiceInfoController);

    InvoiceInfoController.$inject = ['$stateParams', '$state', '$log', 'DataUtils', 'InvoiceInfo', 'Customer'];

    function InvoiceInfoController($stateParams, $state, $log, DataUtils, InvoiceInfo, Customer) {

        var vm = this;

        vm.customerId = $stateParams.customerId;
        vm.invoiceInfos = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.openNew = openNew;
        function openNew(){
            $state.go('invoice-info.new');
        }

        loadAll();

        function loadAll() {
            Customer.get({id: vm.customerId}, function(result){
                vm.customer = result;
                $log.info(result);
            });
            InvoiceInfo.query({customerId: vm.customerId},function(result) {
                vm.invoiceInfos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
