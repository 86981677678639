(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcTechnicsGroupController', TechnicsGroupController);

    TechnicsGroupController.$inject = ['$scope', '$state', 'pcTechnicsGroup'];

    function TechnicsGroupController ($scope, $state, TechnicsGroup) {
        var vm = this;

        vm.technicsGroups = [];

        loadAll();

        function loadAll() {
            TechnicsGroup.query(function(result) {
                vm.technicsGroups = result;
                vm.searchQuery = null;
            });
        }
    }
})();
