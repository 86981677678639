(function(){
    'use strict';
    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('composer',{
            abstact: true,
            url: '/composer',
            parent: 'app'
        });
    }
})();
