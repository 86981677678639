(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MobelixProducts2ProductsDeleteController',MobelixProducts2ProductsDeleteController);

    MobelixProducts2ProductsDeleteController.$inject = ['$uibModalInstance', 'entity', 'MobelixProducts2Products'];

    function MobelixProducts2ProductsDeleteController($uibModalInstance, entity, MobelixProducts2Products) {
        var vm = this;

        vm.mobelixProducts2Products = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MobelixProducts2Products.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
