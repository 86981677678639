(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('Product2PriceController', Product2PriceController);

    Product2PriceController.$inject = ['Product2Price', 'customer', '$stateParams'];

    function Product2PriceController(Product2Price, customer, $stateParams) {

        var vm = this;

        vm.product2Prices = [];
        vm.customerId = $stateParams.customerId;
        vm.customer = customer;
        vm.loadAll = loadAll;
        loadAll();

        function loadAll() {
            Product2Price.query({'customerId': vm.customerId}, function(result) {
                vm.product2Prices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
