(function() {
    'use strict';
    
    angular
        .module('gatewayApp')
        .controller('InvoiceInfoPreviewDetailController', InvoiceInfoPreviewDetailController);
    
    InvoiceInfoPreviewDetailController.$inject = ['$state', '$stateParams', 'entity', '$uibModalInstance', 'InvoiceInfo'];
    
    function InvoiceInfoPreviewDetailController ($state, $stateParams, entity, $uibModalInstance, InvoiceInfo) {
        var vm = this;
        vm.entity = entity;
        vm.clear = clear;
        vm.importInvoicePosition = importInvoicePosition;
        
        function clear(){
            $uibModalInstance.dismiss('cancel');
        }
        
        function importInvoicePosition(invoiceInfoId){
            InvoiceInfo.importInvoicePositions({id:$stateParams.id}, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess (result) {
            $state.go('invoice-info', null, { reload: 'invoice-info' });
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
