(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OttoProducts2ProductsDialogController', OttoProducts2ProductsDialogController);

    OttoProducts2ProductsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'OttoProducts2Products', 'Product'];

    function OttoProducts2ProductsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, OttoProducts2Products, Product) {
        var vm = this;

        vm.ottoProducts2Products = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.products = OttoProducts2Products.getAllProducts({filter: 'ottoproducts2products-is-null'});
        $q.all([vm.ottoProducts2Products.$promise, vm.products.$promise]).then(function() {
            if (!vm.ottoProducts2Products.productId) {
                return $q.reject();
            }
            return OttoProducts2Products.getProductById({id : vm.ottoProducts2Products.productId}).$promise;
        }).then(function(product) {
            vm.products.push(product);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.ottoProducts2Products.id !== null) {
                OttoProducts2Products.update(vm.ottoProducts2Products, onSaveSuccess, onSaveError);
            } else {
                OttoProducts2Products.save(vm.ottoProducts2Products, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:ottoProducts2ProductsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.priceActiveSince = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
