(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ExcelFile', ExcelFile);

    ExcelFile.$inject = ['$resource', 'DateUtils'];

    function ExcelFile ($resource, DateUtils) {
        var resourceUrl =  'importerhellweg/' + 'api/excel-files/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.importDate = DateUtils.convertDateTimeFromServer(data.importDate);
                        data.importToExcelFileContentDate = DateUtils.convertDateTimeFromServer(data.importToExcelFileContentDate);
                        data.importedInMauveDate = DateUtils.convertDateTimeFromServer(data.importedInMauveDate);
                        data.uploadTrackingNr = DateUtils.convertDateTimeFromServer(data.uploadTrackingNr);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'uploadOrder': { method: 'POST',
                params: {id: '@id'},
                url: 'importerhellweg/api/excel-files/:id/invoic',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
