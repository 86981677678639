(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('excel-file', {
            parent: 'entity',
            url: '/excel-file',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.excelFile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/excel-file/excel-files.html',
                    controller: 'ExcelFileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('excelFile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('excel-file-detail', {
            parent: 'excel-file',
            url: '/excel-file/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.excelFile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/excel-file/excel-file-detail.html',
                    controller: 'ExcelFileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('excelFile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ExcelFile', function($stateParams, ExcelFile) {
                    return ExcelFile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'excel-file',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('excel-file-detail.edit', {
            parent: 'excel-file-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excel-file/excel-file-dialog.html',
                    controller: 'ExcelFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ExcelFile', function(ExcelFile) {
                            return ExcelFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('excel-file.new', {
            parent: 'excel-file',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excel-file/excel-file-dialog.html',
                    controller: 'ExcelFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fileName: null,
                                importDate: null,
                                importedToExcelFileContent: false,
                                importToExcelFileContentDate: null,
                                importedInMauve: false,
                                importedInMauveDate: null,
                                trackingNrUpdated: false,
                                uploadTrackingNr: null,
                                artikelNameUpdated: false,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('excel-file', null, { reload: 'excel-file' });
                }, function() {
                    $state.go('excel-file');
                });
            }]
        })
        .state('excel-file.edit', {
            parent: 'excel-file',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excel-file/excel-file-dialog.html',
                    controller: 'ExcelFileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ExcelFile', function(ExcelFile) {
                            return ExcelFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('excel-file', null, { reload: 'excel-file' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('excel-file.delete', {
            parent: 'excel-file',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excel-file/excel-file-delete-dialog.html',
                    controller: 'ExcelFileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ExcelFile', function(ExcelFile) {
                            return ExcelFile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('excel-file', null, { reload: 'excel-file' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('excel-file.preview-trackingNr',{
            parent: 'excel-file',
            url: '/tracking-file/preview',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                $uibModal.open({
                    templateUrl: 'app/entities/excel-file/tracking-file-blob-dialog.html',
                    controller: 'TrackingFileBlobDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve:{
                        entity: function(){
                            return {};
                        }

                    }
                })
                .result.then(function() {
                    $state.go('excel-file', null, { reload: 'excel-file' });
                }, function() {
                    $state.go('excel-file');
                });
            }]

        });
    }

})();
