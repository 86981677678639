(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ParavolExcelFileController', ParavolExcelFileController);

        ParavolExcelFileController.$inject = ['ParavolExcelFile', 'AlertService', '$scope','$http'];

    function ParavolExcelFileController(ParavolExcelFile, AlertService, $scope, $http) {
        var vm = this;
        vm.excelFiles = [];
        vm.uploadedFile = {};
        loadAll();

        function loadAll() {
            ParavolExcelFile.query(function(result) {
                vm.excelFiles = result;
                vm.searchQuery = null;
            });
        }

        vm.uploadParavolFile = function(event){
            if (vm.uploadedFile){
                var formData = new FormData();
                    formData.append('uploadFile', vm.uploadedFile[0]);
                $http({
                    method: 'POST',
                    url: 'paravol/api/excel-import-files-extended/upload-file',
                    headers: {'Content-Type': undefined },
                    data: formData,
                }).success(function(response) {
                    AlertService.success("Excel file imported successfully.");
                    loadAll();
                }).error(function(response){
                    AlertService.error("Error while importing excel file.");
                });
            }

        }
    }

})();
