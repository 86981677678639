(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExcelFileDialogController', ExcelFileDialogController);

    ExcelFileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'ExcelFile', 'FileBlob', 'ExcelContent'];

    function ExcelFileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, ExcelFile, FileBlob, ExcelContent) {
        var vm = this;

        vm.excelFile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.fileblobs = FileBlob.query({filter: 'excelfile-is-null'});
        $q.all([vm.excelFile.$promise, vm.fileblobs.$promise]).then(function() {
            if (!vm.excelFile.fileBlobId) {
                return $q.reject();
            }
            return FileBlob.get({id : vm.excelFile.fileBlobId}).$promise;
        }).then(function(fileBlob) {
            vm.fileblobs.push(fileBlob);
        });
        vm.excelcontents = ExcelContent.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.excelFile.id !== null) {
                ExcelFile.update(vm.excelFile, onSaveSuccess, onSaveError);
            } else {
                ExcelFile.save(vm.excelFile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:excelFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.importDate = false;
        vm.datePickerOpenStatus.importToExcelFileContentDate = false;
        vm.datePickerOpenStatus.importedInMauveDate = false;
        vm.datePickerOpenStatus.uploadTrackingNr = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
