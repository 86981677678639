(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoiceFileDetailController', InvoiceFileDetailController);

    InvoiceFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity'];

    function InvoiceFileDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity) {
        var vm = this;

        vm.invoiceFile = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('gatewayApp:invoiceFileUpdate', function(event, result) {
            vm.invoiceFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
