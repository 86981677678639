/* globals $ */
(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('filesInput', filesInput);

    function filesInput () {
        var directive = {
            require: "ngModel",
            link: function postLink(scope,elem,attrs,ngModel) {
            elem.on("change", function(e) {
                var files = elem[0].files;
                ngModel.$setViewValue(files);
            })
            }
        };
        return directive;
    }
})();
