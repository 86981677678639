(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('composer.config.technics-key', {
            parent: 'composer.config',
            url: '/technics-group/{technicsGroupId}/technics-key',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'productComposerApp.technicsKey.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/productComposer/config/technics-key/technics-keys.html',
                    controller: 'pcTechnicsKeyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('technicsGroup');
                    $translatePartialLoader.addPart('technicsKey');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('composer.config.technics-key.new', {
            parent: 'composer.config.technics-key',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/config/technics-key/technics-key-new.html',
                    controller: 'pcTechnicsKeyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                displayOrder: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('composer.config.technics-key', null, { reload: 'composer.config.technics-key' });
                }, function() {
                    $state.go('composer.config.technics-key');
                });
            }]
        })
        .state('composer.config.technics-key.edit', {
            parent: 'composer.config.technics-key',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/config/technics-key/technics-key-dialog.html',
                    controller: 'pcTechnicsKeyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['pcTechnicsKey', function(TechnicsKey) {
                            return TechnicsKey.get({technicsGroupId: $stateParams.technicsGroupId, id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.config.technics-key', null, { reload: 'composer.config.technics-key' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.config.technics-key.delete', {
            parent: 'composer.config.technics-key',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/config/technics-key/technics-key-delete-dialog.html',
                    controller: 'pcTechnicsKeyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['pcTechnicsKey', function(TechnicsKey) {
                            return TechnicsKey.get({technicsGroupId:$stateParams.technicsGroupId, id : $stateParams.id}).$promise;
                        }],
                        referencedProducts: ['pcTechnicsKey', function(TechnicsKey) {
                            return TechnicsKey.getReferencedProducts({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.config.technics-key', null, { reload: 'composer.config.technics-key' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
