(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrderPositionController', OrderPositionController);

    OrderPositionController.$inject = ['OrderPosition'];

    function OrderPositionController(OrderPosition) {

        var vm = this;

        vm.orderPositions = [];

        loadAll();

        function loadAll() {
            OrderPosition.query(function(result) {
                vm.orderPositions = result;
                vm.searchQuery = null;
            });
        }
    }
})();
