'use strict';

angular.module('gatewayApp')
            .factory('ExcelExport', ExcelExport);

ExcelExport.$inject = ['$log', 'uiGridExporterService', 'uiGridExporterConstants'];

function ExcelExport($log, uiGridExporterService, uiGridExporterConstants) {
    return {
        export: function(gridApi, startDate, endDate){
            $log.info('start export');
            var rows = uiGridExporterService.getData(gridApi.grid, uiGridExporterConstants.VISIBLE, uiGridExporterConstants.VISIBLE);
            var sheet = {};
            gridApi.grid.columns.forEach(function (col, i) {
                if (col.visible) {
                    var loc = XLSX.utils.encode_cell({r: 0, c: i});

                    sheet[loc] = {
                        v: col.displayName,
                        t: 's'
                    };
                }
            });

            var endLoc;
            rows.forEach(function (row, ri) {
                ri +=1;

                gridApi.grid.columns.forEach(function (col, ci) {
                    if(ci < 6){
                        var loc = XLSX.utils.encode_cell({r: ri, c: ci});
                        var valueUnformated = row[ci].value;
                        var val = valueUnformated;
                        if(ci === 3 &&  angular.isNumber(valueUnformated)){
                            val = valueUnformated.toFixed(2);
                            var valAsString = val.toString();
                            val = valAsString.replace(/\./g, ',');
                        }
                        sheet[loc] = {
                            v: val,
                            t: 's'
                        };

                        endLoc = loc;
                    }

                });
            });

            sheet['!ref'] = XLSX.utils.encode_range({ s: 'A1', e: endLoc });

            var workbook = {
                SheetNames: ['Sheet1'],
                Sheets: {
                    Sheet1: sheet
                }
            };

            var wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
            var wbout = XLSX.write(workbook, wopts);

            var title = 'export_von_' + startDate + '_bis_' + endDate + '.xlsx';
            // eslint-disable-next-line no-undef
            saveAs(new Blob([s2ab(wbout)], {type: ""}), title);
            $log.info('done export');
        }
    };


    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
}
