(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcTechnicsKeyNameController', TechnicsKeyNameController);

    TechnicsKeyNameController.$inject = ['$scope', '$state', 'pcTechnicsKeyName'];

    function TechnicsKeyNameController ($scope, $state, TechnicsKeyName) {
        var vm = this;

        vm.technicsKeyNames = [];

        loadAll();

        function loadAll() {
            TechnicsKeyName.query(function(result) {
                vm.technicsKeyNames = result;
                vm.searchQuery = null;
            });
        }
    }
})();
