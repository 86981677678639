'use strict';

angular.module('gatewayApp')
        .factory('SalesOnItem', SalesOnItem);

SalesOnItem.$inject = ['$resource'];

function SalesOnItem($resource) {

    return {
        salesOnItem: $resource('bi/api/charts/:verb', {
            verb: 'salesonitem',
            startDate: '@startDate',
            endDate: '@endDate'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        }),
        salesOnItemChart: $resource('bi/api/charts/:verb', {
            verb: 'salesonitemchart',
            startDate: '@startDate',
            endDate: '@endDate',
            Supplier: '@Supplier',
            ProductGroup: '@ProductGroup'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        }),
        getSupplierID: $resource('bi/api/suppliers', {
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        }),
        getProductGroupID: $resource('bi/api/product-groups', {
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        })
    };
}
