(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('composer.product', {
            parent: 'composer',
            url: '/product',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'productComposerApp.product.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/productComposer/product/products.html',
                    controller: 'pcProductController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('productGroup');
                    $translatePartialLoader.addPart('technicsGroup');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('composer.product.new', {
            parent: 'composer.product',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/product/product-new.html',
                    controller: 'pcProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                offerId: null,
                                title: null,
                                description: null,
                                recommendation: null,
                                hint: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('composer.product', null, { reload: 'composer.product' });
                }, function() {
                    $state.go('composer.product');
                });
            }]
        })
        .state('composer.product.edit', {
            parent: 'composer.product',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/product/product-dialog.html',
                    controller: 'pcProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['pcProduct', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.product', null, { reload: 'composer.product' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.product.delete', {
            parent: 'composer.product',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/product/product-delete-dialog.html',
                    controller: 'pcProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['pcProduct', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.product', null, { reload: 'composer.product' });
                }, function() {
                    $state.go('^');
                });
            }]
        })                
        .state('composer.product-preview', {
            parent: 'composer.product',
            url: '/{id}/preview',
            data: {
                authorities: ['ROLE_USER']
            },
            
            
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/product/product-preview.html',
                    controller: 'pcProductPreviewController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['pcProduct', function(Product) {
                            return Product.preview({id : $stateParams.id}).$promise;
                        }],
                        entityProduct: ['pcProduct', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.product-preview', null, { reload: 'composer.product' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.product-sourcecode', {
            parent: 'composer.product',
            url: '/{id}/sourcecode',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/product/product-sourcecode.html',
                    controller: 'pcProductSourceCodeController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['pcProduct', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.product-sourcecode', null, { reload: 'composer.product' });
                }, function() {
                    $state.go('^');
                });
            }]
        });                        
    }

})();
