(function(){
    'use strict';

    angular
      .module('gatewayApp')
      .controller('ImporterController', ImporterController);

    ImporterController.$inject = ['$log', 'ImporterService', 'LidlProducts2Products', '$scope', '$timeout', '$http', '$location'];

    function ImporterController($log, ImporterService, LidlProducts2Products, $scope, $timeout, $http, $location){
        var vm = this;
        $scope.uploadStatus = {};
        $scope.uploadedFile = null;
        vm.uploadFileLidl;
        $scope.uploadStatusForm = false;
        vm.downloadAvailability = downloadAvailability;
        vm.uploadLidlFile = uploadLidlFile;
        init();
        function downloadAvailability(channel){
            $log.info('enter downloadAvailability');
            var file = ImporterService.getAvailability.get({channel:channel});
            file.$promise.then(function () {
                var response = JSON.parse(JSON.stringify(file));
                saveAs(new Blob([s2ab(response.data)]), response.filename);
            });
        }
        vm.availabilityConfigs = ImporterService.getAvailability.getAllAvailabilityConfigs();


        function s2ab(csvObj) {
            var csv = "";
            angular.forEach(csvObj, function (value) {
                csv += value;
            });
            var buf = new ArrayBuffer(csv.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xFF;
            return buf;
        }

        function ottoStatus(){
            var x = ImporterService.getAvailability.getLastUploadOttoAvailability()
            .$promise
                    .then(function(data){
                        $scope.uploadStatus.content = data.content;
                        $scope.uploadStatus.processingMessage = data.processingMessage;
                        $scope.uploadStatus.processingStatus = data.processingStatus;
                        $scope.uploadStatus.uploadDate = data.uploadDate;

                        checkOttoStatus($scope.uploadStatus);
                    });
        }

        $scope.uploadOttoFile = function(event){
            if (!event[0].files.length)
                    return null;

                $scope.content = new FormData();
                $scope.content.append('file', event[0].files[0]);
                $scope.$apply();

                $http({
                    method: 'POST',
                    url: 'importer/api/importer/otto/availability',
                    headers: {'Content-Type': undefined },
                    data: {"disposibilityContent": $scope.content },
                }).success(function(response) {

                    ottoStatus();
                });
                console.log($scope.content);


        }

        $scope.postOttoUpdateContent = function(){
            $scope.uploadStatus.processingStatus="IN_PROGRESS";
            $http({
                method: 'POST',
                url: 'importer/api/importer/otto/availability/cvs',
                headers: 'Content-Type: application/json;charset=UTF-8',
                data: $('#postOttoUpdateContentTxt').val(),
            }).success(function(response) {
                $timeout( function(){
                    ottoStatus();
                }, 5000);
            });
        }

        $scope.setActionFormAction = function(e){
            $timeout(function(){
                $scope.uploadStatusForm = e;
            }, 200);
        }

        function checkOttoStatus(param){
            if(param.processingStatus==="IN_PROGRESS"){
                $timeout( function(){
                    ottoStatus();
                }, 5000);
            }
        }

        function init(){
            ottoStatus();
        }

        $scope.uploadHellwegFile = function(event){
            vm.error = null;
            vm.success = null;
            if (!event[0].files.length)
                    return null;

                    var formData = new FormData();
                    formData.append('uploadfile', event[0].files[0]);

                $http({
                    method: 'POST',
                    url: 'importer/api/hellweg-products-2-products-extended/upload-file',
                    headers: {'Content-Type': undefined },
                    data: formData,
                }).success(function(response) {
                    vm.error = null;
                    vm.success = response.message;
                }).error(function(response){
                    vm.error = "Error while uploading file of HellwegProduct.";
                    vm.success = null;
                });
        }

        function uploadLidlFile(event){
            vm.error = null;
            vm.success = null;
            if (vm.uploadFileLidl){
                var formData = new FormData();
                formData.append('uploadfile', vm.uploadFileLidl);

                $http({
                    method: 'POST',
                    url: 'importer/api/lidl-products-2-products-extended/upload-file',
                    headers: {'Content-Type': undefined },
                    data: formData,
                }).success(function(response) {
                    vm.error = null;
                    vm.success = response.message;
                }).error(function(response){
                    vm.error = "Error while uploading file of LidlProduct.";
                    vm.success = null;
                });

            }
        }

        $scope.viewHellwegProducts2Products = function () {
            $location.url('hellweg-products-2-products');
        }

        $scope.uploadAmazonProducts2ProductsFile = function(event){
            vm.error = null;
            vm.success = null;
            if (!event[0].files.length)
                    return null;

                    var formData = new FormData();
                    formData.append('uploadfile', event[0].files[0]);

                $http({
                    method: 'POST',
                    url: 'importer/api/amazon-products-2-products-extended/upload-file',
                    headers: {'Content-Type': undefined },
                    data: formData,
                }).success(function(response) {
                    vm.error = null;
                    vm.success = response.message;
                }).error(function(response){
                    vm.error = "Error while uploading file of AmazonProducts2Products.";
                    vm.success = null;
                });
        }

        $scope.viewAmazonProducts2Products = function () {
            $location.url('amazon-products-2-products');
        }

        $scope.viewMobelixProducts2Products = function () {
            $location.url('mobelix-products-2-products');
        }

        $scope.viewNormaProducts2Products = function () {
            $location.url('norma-products-2-products');
        }

        $scope.uploadOttoProducts2ProductsFile = function(event){
            vm.error = null;
            vm.success = null;
            if (!event[0].files.length)
                    return null;

                    var formData = new FormData();
                    formData.append('uploadfile', event[0].files[0]);

                $http({
                    method: 'POST',
                    url: 'importer/api/otto-products-2-products-extended/upload-file',
                    headers: {'Content-Type': undefined },
                    data: formData,
                }).success(function(response) {
                    vm.error = null;
                    vm.success = response.message;
                }).error(function(response){
                    vm.error = "Error while uploading file of OttoProducts2Products.";
                    vm.success = null;
                });
        }

        $scope.viewOttoProducts2Products = function () {
            $location.url('otto-products-2-products');
        }

        $scope.viewLidlProducts2Products = function (){
            $location.url('lidl-products-2-products');
        }
    }

})();
