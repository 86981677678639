(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('fileOnChange', fileOnChange);

    function fileOnChange () {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function($scope, el, attrs, ngModel){
                el.bind('change', function(event){
                    var files = event.target.files;
                    var file = files[0];

                    ngModel.$setViewValue(file);
                    $scope.$apply();
                });
            }
        };

        return directive;
    }

})();
