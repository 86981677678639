(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TechnicsController', TechnicsController);

    TechnicsController.$inject = ['$log', '$scope', '$state', '$stateParams', 'Technics', 'pcProduct', 'AlertService'];

    function TechnicsController ( $log, $scope, $state, $stateParams, Technics, Product, AlertService) {
        var vm = this;
        
        vm.technics = [];
        vm.productId = $stateParams.productId;
        vm.product = Product.get({id: $stateParams.productId});
        vm.updateIsHighlight = updateIsHighlight;
        vm.technicsToDelete = [];
        
        
        vm.checkAll = checkAll;
        vm.uncheckAll = uncheckAll;        
        vm.delete_technics_selection = delete_technics_selection;        
        
        loadAll();
        

        function loadAll() {
            Technics.query({ productId:vm.productId }, function(result) {
                vm.technics = result;
            });
        }
        
        function updateIsHighlight(id){            
            $log.info("id");
            $log.info(id);
        }
        
        function uncheckAll(){
            vm.technicsToDelete = [];
        }
        
        function checkAll(){
            vm.technicsToDelete = vm.technics;
        }
                        
        function delete_technics_selection(){            
            
            if(vm.technicsToDelete.length === 0){
                AlertService.error("productComposerApp.technics.delete_selection.message");
            }
            else{
                $state.go('technics.delete_selection', {product: vm.product,
                    technicsToDelete: vm.technicsToDelete});
            }
        }
    }
})();
