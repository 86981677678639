(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcPictureDialogFromMasterController', PictureDialogFromMasterController);

    PictureDialogFromMasterController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'pcPicture', 'pcProduct', 'AlertService'];

    function PictureDialogFromMasterController ($stateParams, $uibModalInstance, entity, Picture, Product, AlertService) {
        var vm = this;

        vm.picture = entity;
        vm.picture.productId = $stateParams.productId;
        vm.picture.picturesToCreate = [];
        vm.availablePictures = [];
        vm.product = Product.get({id: $stateParams.productId});
        vm.clear = clear;
        vm.save = save;
        vm.checkForPicturesForMaster = checkForPicturesForMaster;
        vm.checkAll = checkAll;
        vm.uncheckAll = uncheckAll;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
       

        function onSearchSuccess (result) {
            vm.availablePictures = result;
            vm.isSearching = false;
            vm.showAvailablePictures = true;
            vm.checkAll();
        }
        function onSearchError (result) {
            vm.isSearching = false;
            AlertService.error(result);
        }
        
        function checkForPicturesForMaster () {
            var pictureURL = vm.picture.url;
            Picture.getavailablepic({},pictureURL, onSearchSuccess, onSearchError);
            
            vm.isSearching = true;
        }
        
        function save(){
            if(vm.picture.picturesToCreate.length !== 0){
                Picture.createpic({productId: $stateParams.productId}, vm.picture, onSaveSuccess, onSaveError);
            }else{
                AlertService.error("no picture!");
            }
        }
        
        function onSaveSuccess(result){
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onSaveError(result){
            vm.isSaving = false;
            AlertService.error(result);
        }
        
        function checkAll(){
            vm.picture.picturesToCreate = vm.availablePictures.map(function(picture){
                return picture.picNr;
            });
            vm.isSaving = false;
        }
        
        function uncheckAll(){
            vm.picture.picturesToCreate = [];
        }

    }
})();
