(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HellwegProducts2ProductsDeleteController',HellwegProducts2ProductsDeleteController);

    HellwegProducts2ProductsDeleteController.$inject = ['$uibModalInstance', 'entity', 'HellwegProducts2Products'];

    function HellwegProducts2ProductsDeleteController($uibModalInstance, entity, HellwegProducts2Products) {
        var vm = this;

        vm.hellwegProducts2Products = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            HellwegProducts2Products.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
