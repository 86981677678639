(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('composer.picture', {
            parent: 'composer',
            url: '/product/{productId}/picture',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'productComposerApp.picture.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/productComposer/picture/pictures.html',
                    controller: 'pcPictureController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('picture');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('composer.picture.new', {
            parent: 'composer.picture',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/picture/picture-new.html',
                    controller: 'pcPictureDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                titel: null,
                                description: null,
                                location: null,
                                locationSmallPicture: null,
                                displayOrder: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('composer.picture', null, { reload: 'composer.picture' });
                }, function() {
                    $state.go('composer.picture');
                });
            }]
        })
        .state('composer.picture.edit', {
            parent: 'composer.picture',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/picture/picture-dialog.html',
                    controller: 'pcPictureDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['pcPicture', function(Picture) {
                            return Picture.get({productId:$stateParams.productId, id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.picture', null, { reload: 'composer.picture' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.picture.delete', {
            parent: 'composer.picture',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/picture/picture-delete-dialog.html',
                    controller: 'pcPictureDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['pcPicture', function(Picture) {
                            return Picture.get({productId:$stateParams.productId, id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.picture', null, { reload: 'composer.picture' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('composer.picture.delete_selection', {
            parent: 'composer.picture', // zugeh. url: '/product/{productId}/picture',                        
            url: '/delete_selection',             
            params: {picturesToDelete: null,product: null},
            // nach den hier aufgelisteten Variablen soll der State Ausschau halten.
            // Diese sollten ihm über link (ui-sref="..." aus picture.html, entspr. POST eines Param.) 
            // oder über $state.go(...) übergeben worden sein.
            
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/picture/picture-delete-selection-dialog.html',
                    controller: 'pcPictureDeleteSelectionController',
                    controllerAs: 'vm',
                    size: 'md',                    
                    resolve: {                                                 
                    }
                }).result.then(function() {
                    $state.go('composer.picture', null, { reload: 'composer.picture' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('composer.picture-from-master', {
            parent: 'composer.picture',
            url: '/create-from-master',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                $uibModal.open({
                    templateUrl: 'app/productComposer/picture/picture-dialog-from-master.html',
                    controller: 'pcPictureDialogFromMasterController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                            return {
                                productId: null
                            };
                        }
                    }
                    
                }).result.then(function(){
                    $state.go('composer.picture', null, {reload: 'composer.picture'});
                }, function(){
                    $state.go('composer.picture');
                });
            }]
        });
    }

})();
