(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TechnicsMigrationDialogController', TechnicsMigrationDialogController);

    TechnicsMigrationDialogController.$inject = [ '$q', '$state', '$uibModalInstance', 'AlertService', 'pcProduct', 'product', 'productGroups' ];
    
    function TechnicsMigrationDialogController($q, $state, $uibModalInstance, AlertService, Product, product, productGroups){
        var vm = this;
        vm.product = product;
        vm.productGroups = productGroups;
        vm.clear = clear;
        vm.technicsGroupConfig = technicsGroupConfig;
        vm.showTechnicsGroup = false;
        vm.checkMigration = checkMigration;
        vm.migrateProductTechnicsToTechnicsGroup = migrateProductTechnicsToTechnicsGroup;
        
        checkTechnicsGroup();
        
        function checkTechnicsGroup(){
            $q.all([vm.product.$promise, vm.productGroups.$promise]).then(function(){
                technicsGroupConfig();
            });
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function migrateProductTechnicsToTechnicsGroup(productId, productGroupId, technicsGroupId){
            Product.migrateToTechnicsGroup({productId: productId, productGroupId: productGroupId, technicsGroupId:technicsGroupId}, migrateOnSuccess, onError);
        }

        function migrateOnSuccess(){
            $state.go('technics', null, { reload: 'technics' });
            $uibModalInstance.close(true);
            
        }
        
        function checkMigration(productId, technicsGroupId){
            Product.checkMigration({productId: productId, technicsGroupId:technicsGroupId}, onSuccess, onError);
        }
        
        function onSuccess(response){
            vm.orphanTechnics = response.orphanTechnics;
            vm.newTechnicsKeyName = response.newTechnicsKeyName;
            vm.showTechnicsDiff = true;
        }
        
        function onError(error){
            AlertService.error(error.data.message);
        }
        
        function technicsGroupConfig(){
            vm.orphanTechnics = [];
            vm.newTechnicsKeyName = [];
            vm.showTechnicsDiff = false;
            if(vm.product.productGroup){
                vm.technicsGroups = vm.product.productGroup.technicsGroups;
                if(vm.technicsGroups.length > 0){
                    if(vm.technicsGroups.length > 1){
                        vm.showTechnicsGroup = true;
                    }else{
                        vm.product.technicsGroup = vm.technicsGroups[0];
                        vm.showTechnicsGroup = false;
                    }
                    vm.isSaving = false;
                }else{
                    vm.product.technicsGroup = '';
                    vm.isSaving = true;
                    AlertService.error("Warengruppe {{param}} falsch konfiguriert! Hat keine Technische Vorlage zugewisen!", {param:vm.product.productGroup.name});
                } 
            }
        }
    }

})();
