(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('pcProduct', pcProduct);

    pcProduct.$inject = ['$resource'];

    function pcProduct ($resource) {
        var resourceUrl =  'composer/api/products/:id';

        return $resource(resourceUrl, {productId:'', technicsGroupId: ''}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'preview': { method: 'GET', 
                transformResponse: function (data) {
                    return {html: data};
                }, 
                url: 'composer/api/products/:id/preview'},
            'sourcecode': { method: 'GET', url: 'composer/api/products/:id/sourcecode'},
            'createFromClone': { method: 'POST', url: 'composer/api/products/createFromMain'},
            'getByOfferId': {method: 'GET', params: {offerId:''}, url: 'composer/api/products/search'},
            'checkMigration': {method: 'GET', params: {productId:'', technicsGroupId: ''}, url: 'composer/api/products/:productId/technics/:technicsGroupId/migrationDiff'},
            'migrateToTechnicsGroup': {method: 'POST', params: {productId:'@productId', productGroupId: '@productGroupId', technicsGroupId: '@technicsGroupId'}, url: 'composer/api/productGroups/:productGroupId/products/:productId/technics/:technicsGroupId/migration'}
        });
    }
})();
