(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AmazonProducts2ProductsController', AmazonProducts2ProductsController);

    AmazonProducts2ProductsController.$inject = ['AmazonProducts2Products', 'ParseLinks', 'AlertService', 'paginationConstants'];

    function AmazonProducts2ProductsController(AmazonProducts2Products, ParseLinks, AlertService, paginationConstants) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.busyLoadingData = false;
        vm.amazonProducts2Products = [];
        vm.setExported4Availability = setExported4Availability;
        vm.amazonProducts2ProductsToUpdate = [];
        vm.bulkUpdateExported4Availability = bulkUpdateExported4Availability;
        vm.onCheckboxClick = onCheckboxClick;

        loadAll();

        function loadAll () {
            vm.busyLoadingData = true;
            AmazonProducts2Products.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.amazonProducts2Products.push(data[i]);
                }
                vm.busyLoadingData = false;
            }

            function onError(error) {
                vm.busyLoadingData = false;
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.amazonProducts2Products = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function setExported4Availability (amazonProducts2Products, isExported4Availability) {
            amazonProducts2Products.exported4Availability = isExported4Availability;
            amazonProducts2Products.productId = amazonProducts2Products.product.id;
            AmazonProducts2Products.update(amazonProducts2Products, function () {
                loadAll();
            });
        }

        function onCheckboxClick(amazonProducts2Products, event){

            if(amazonProducts2Products.select)
                vm.amazonProducts2ProductsToUpdate.push(amazonProducts2Products);
            else {
                var index = vm.amazonProducts2ProductsToUpdate.indexOf(amazonProducts2Products);
                if(index > -1)
                    vm.amazonProducts2ProductsToUpdate.splice(index, 1);

            }

        }

        function bulkUpdateExported4Availability(isEnabled){

            vm.amazonProducts2ProductsToUpdate = vm.amazonProducts2ProductsToUpdate.map(function(product) {
                product.exported4Availability = isEnabled;
                product.productId = product.product.id;
                return product;
            })

            AmazonProducts2Products.bulkUpdate(vm.amazonProducts2ProductsToUpdate, function(res) {
                vm.amazonProducts2ProductsToUpdate = [];
                loadAll();
            })
        };
    }
})();
