(function () {
    'use strict';

    angular.module('gatewayApp')
            .controller(
                    'SalesAverageController', SalesAverageController);

    SalesAverageController.$inject = ['$scope', '$location', '$state', '$log', 'SalesOnItem', 'SalesAverage', 'ChartCommons', 'ExcelExport'];

    function SalesAverageController($scope, $location, $state, $log, SalesOnItem, SalesAverage, ChartCommons, ExcelExport) {
        var vm = this;

        $scope.isExistingDataToExport = false;
        $scope.gridOptions = {
            exporterSuppressColumns:[],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $log.info('navigation event');
            },
            exporterFieldCallback : function ( grid, row, col, value ){
                var cellRegex =  new RegExp('cell*');
                if ( cellRegex.test( col.name) ){
                    value = value.amount;
                }
                return value;
            },
            exporterFieldFormatCallback: function(grid, row, col, value ){
                return value;
            }
        };

        var getSupplierID = SalesOnItem.getSupplierID.query({});
        getSupplierID.$promise.then(function () {
            $scope.supplierOptions = getSupplierID;
            $scope.supplierOptions.unshift({id: 0, company: 'keine Einschränkung'});
            if ($state.params.Supplier) {
                $scope.Supplier = $scope.supplierOptions[$state.params.Supplier];
            } else {
                $scope.Supplier = $scope.supplierOptions[0];
            }
        });
        var getProductGroupID = SalesOnItem.getProductGroupID.query({});
        getProductGroupID.$promise.then(function () {
            $scope.prodGroupOptions = getProductGroupID;
            $scope.prodGroupOptions.unshift({id: 0, nameUi: 'keine Einschränkung'});
            if ($state.params.ProductGroup) {
                $scope.ProductGroup = $scope.prodGroupOptions[$state.params.ProductGroup];
            } else {
                $scope.ProductGroup = $scope.prodGroupOptions[0];
            }
        });
        $scope.date = {
            startDate: null,
            endDate: null
        };

        $scope.displayChart = function () {
            $log.info('start display');
            var supplier, productgroup;
            if ($scope.Supplier) {
                supplier = $scope.Supplier.id > 0 ? $scope.Supplier.id : undefined;
            }
            if ($scope.ProductGroup) {
                productgroup = $scope.ProductGroup.id > 0 ? $scope.ProductGroup.id : undefined;
            }
            var salesOnItemChart = SalesAverage.salesAverage.get({
                startDate: $scope.date.startDate,
                endDate: $scope.date.endDate,
                Supplier: supplier,
                ProductGroup: productgroup
            });
            $location.search({startDate: $scope.date.startDate, endDate: $scope.date.endDate, Supplier: supplier,
                ProductGroup: productgroup});

            salesOnItemChart.$promise.then(function () {
                $scope.salesOnItemChart = salesOnItemChart;
                
                var columnDefs = ChartCommons.generateChartColumnsConfig(salesOnItemChart);
                $scope.columnDefs = columnDefs;
                var tableConfig = ChartCommons.generateChartSeries(salesOnItemChart);
                $scope.myData = tableConfig.data;
                $scope.isExistingDataToExport = true;
                $scope.gridOptions = {
                    data: $scope.myData,
                    enableHorizontalScrollbar: 2,
                    columnDefs: $scope.columnDefs,
                    enablePinning: true
                };
            });
            $log.info('done display');
        }

        $scope.exportExcel = function(){
            return  ExcelExport.export($scope.gridApi, $scope.date.startDate, $scope.date.endDate);
        };

    }


})();
