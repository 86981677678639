(function () {
    'use strict';

    angular.module('gatewayApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {

        $stateProvider
                .state('bi.chart1', {
                    parent: 'bi',
                    url: '/bi/bestSale',
                    data: {
                        authorities: ['ROLE_USER'],
                        pageTitle: 'xlabiqBiApp.chart1.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/bi/bestSaleOnCountry/bestSale.html',
                            controller: 'BestSaleOnCountryController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('bestSale');
                            return $translate.refresh();
                        }]
                    }
                });
    }


})();
