(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MobelixProducts2ProductsController', MobelixProducts2ProductsController);

    MobelixProducts2ProductsController.$inject = ['MobelixProducts2Products', 'ParseLinks', 'AlertService', 'paginationConstants'];

    function MobelixProducts2ProductsController(MobelixProducts2Products, ParseLinks, AlertService, paginationConstants) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.busyLoadingData = false;
        vm.mobelixProducts2Products = [];
        vm.mobelixProducts2ProductsToUpdate = [];
        vm.setExported4Availability = setExported4Availability;
        vm.bulkUpdateExported4Availability = bulkUpdateExported4Availability;
        vm.onCheckboxClick = onCheckboxClick;

        loadAll();

        function loadAll () {
            vm.busyLoadingData = true;
            MobelixProducts2Products.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.mobelixProducts2Products.push(data[i]);
                }
                vm.busyLoadingData = false;
            }

            function onError(error) {
                vm.busyLoadingData = false;
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.mobelixProducts2Products = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function onCheckboxClick(mobelixProducts2Products, event){
            if(mobelixProducts2Products.select)
                vm.mobelixProducts2ProductsToUpdate.push(mobelixProducts2Products);
            else {
                var index = vm.mobelixProducts2ProductsToUpdate.indexOf(mobelixProducts2Products);
                if(index > -1)
                    vm.mobelixProducts2ProductsToUpdate.splice(index, 1);

            }

        }

        // on bulk update
        function bulkUpdateExported4Availability(isEnabled){

            vm.mobelixProducts2ProductsToUpdate = vm.mobelixProducts2ProductsToUpdate.map(function(product) {
                product.exported4Availability = isEnabled;
                product.productId = product.product.id;
                return product;
            })

            MobelixProducts2Products.bulkUpdate(vm.mobelixProducts2ProductsToUpdate, function(res) {
                vm.mobelixProducts2ProductsToUpdate = [];
                loadAll();
            })
        };

        function setExported4Availability (mobelixProducts2Products, isExported4Availability) {
            mobelixProducts2Products.exported4Availability = isExported4Availability;
            mobelixProducts2Products.productId = mobelixProducts2Products.product.id;
            MobelixProducts2Products.update(mobelixProducts2Products, function () {
                loadAll();
            });
        }
    }
})();
