(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AmazonProducts2ProductsDetailController', AmazonProducts2ProductsDetailController);

    AmazonProducts2ProductsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AmazonProducts2Products', 'Product'];

    function AmazonProducts2ProductsDetailController($scope, $rootScope, $stateParams, previousState, entity, AmazonProducts2Products, Product) {
        var vm = this;

        vm.amazonProducts2Products = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:amazonProducts2ProductsUpdate', function(event, result) {
            vm.amazonProducts2Products = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
