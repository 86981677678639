(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HellwegProducts2ProductsDialogController', HellwegProducts2ProductsDialogController);

    HellwegProducts2ProductsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'HellwegProducts2Products'];

    function HellwegProducts2ProductsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, HellwegProducts2Products) {
        var vm = this;

        vm.hellwegProducts2Products = entity;
        vm.clear = clear;
        vm.save = save;

        vm.products = HellwegProducts2Products.getAllProducts({filter: 'hellwegproducts2products-is-null'});
        $q.all([vm.hellwegProducts2Products.$promise, vm.products.$promise]).then(function() {
            if (!vm.hellwegProducts2Products.productId) {
                return $q.reject();
            }
            return HellwegProducts2Products.getProductById({id : vm.hellwegProducts2Products.productId}).$promise;
        }).then(function(product) {
            vm.products.push(product);
        });
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.hellwegProducts2Products.id !== null) {
                HellwegProducts2Products.update(vm.hellwegProducts2Products, onSaveSuccess, onSaveError);
            } else {
                var ean = vm.hellwegProducts2Products.productId.id;
                vm.hellwegProducts2Products.productId = ean;
                HellwegProducts2Products.save(vm.hellwegProducts2Products, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:hellwegProducts2ProductsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
