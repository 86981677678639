(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AmazonProducts2ProductsDeleteController',AmazonProducts2ProductsDeleteController);

    AmazonProducts2ProductsDeleteController.$inject = ['$uibModalInstance', 'entity', 'AmazonProducts2Products'];

    function AmazonProducts2ProductsDeleteController($uibModalInstance, entity, AmazonProducts2Products) {
        var vm = this;

        vm.amazonProducts2Products = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AmazonProducts2Products.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
