(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExcelFileController', ExcelFileController);

    ExcelFileController.$inject = ['ExcelFile', 'AlertService'];

    function ExcelFileController(ExcelFile, AlertService) {

        var vm = this;

        vm.uploadOrder = uploadOrder;
        vm.excelFiles = [];

        loadAll();

        function loadAll() {
            ExcelFile.query(function(result) {
                vm.excelFiles = result;
                vm.searchQuery = null;
            });
        }

        function uploadOrder(excelFileId) {
            vm.isSending = true;
            console.log(excelFileId);
            ExcelFile.uploadOrder({id: excelFileId}, onSuccess, onError);
        }
        function onSuccess() {
            loadAll();
            vm.isSending = false;
        }

        function onError(error) {
            vm.isSending = false;
            AlertService.error(error.data.message);
        }
    }
})();
