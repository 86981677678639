(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Product2Price', Product2Price);

    Product2Price.$inject = ['$resource', 'DateUtils'];

    function Product2Price ($resource, DateUtils) {
        var resourceUrl =  'invoic/' + 'api/product-2-prices/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                url: 'invoic/api/customers/:customerId/product-2-prices/:id'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.priceActiveSince = DateUtils.convertLocalDateFromServer(data.priceActiveSince);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.priceActiveSince = DateUtils.convertLocalDateToServer(copy.priceActiveSince);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                url: 'invoic/api/customers/:customerId/product-2-prices',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.priceActiveSince = DateUtils.convertLocalDateToServer(copy.priceActiveSince);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
