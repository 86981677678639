(function () {
    'use strict';

    angular.module('gatewayApp')
            .controller(
                    'SalesOnItemController', SalesOnItemController);

    SalesOnItemController.$inject = ['$scope', '$location', '$state', '$log', 'SalesOnItem', 'ChartCommons', 'ExcelExport'];

    function SalesOnItemController($scope, $location, $state, $log, SalesOnItem, ChartCommons, ExcelExport) {

        var vm = this;
        $scope.isExistingDataToExport = false;

        $scope.gridOptions = {
            exporterSuppressColumns:[],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $log.info('navigation event');
            },
            exporterFieldCallback : function ( grid, row, col, value ){
                var cellRegex =  new RegExp('cell*');
                if ( cellRegex.test( col.name) ){
                    value = value.amount;
                }
                return value;
            },
            exporterFieldFormatCallback: function(grid, row, col, value ){
                return value;
            }
        };

        var getSupplierID = SalesOnItem.getSupplierID.query({});
        getSupplierID.$promise.then(function () {
            $scope.supplierOptions = getSupplierID;
            $scope.supplierOptions.unshift({id: 0, company: 'keine Einschränkung'});
            if ($state.params.Supplier) {
                $scope.Supplier = $scope.supplierOptions[$state.params.Supplier];
            } else {
                $scope.Supplier = $scope.supplierOptions[0];
            }
        });
        var getProductGroupID = SalesOnItem.getProductGroupID.query({});
        getProductGroupID.$promise.then(function () {
            $scope.prodGroupOptions = getProductGroupID;
            $scope.prodGroupOptions.unshift({id: 0, nameUi: 'keine Einschränkung'});
            if ($state.params.ProductGroup) {
                $scope.ProductGroup = $scope.prodGroupOptions[$state.params.ProductGroup];
            } else {
                $scope.ProductGroup = $scope.prodGroupOptions[0];
            }
        });
        $scope.date = {
            startDate: null,
            endDate: null
        };


        $scope.displayChart = function () {
            var supplier, productgroup;
            if ($scope.Supplier) {
                supplier = $scope.Supplier.id > 0 ? $scope.Supplier.id : undefined;
            }
            if ($scope.ProductGroup) {
                productgroup = $scope.ProductGroup.id > 0 ? $scope.ProductGroup.id : undefined;
            }
            var salesOnItemChart = SalesOnItem.salesOnItemChart.get({
                startDate: $scope.date.startDate,
                endDate: $scope.date.endDate,
                Supplier: supplier,
                ProductGroup: productgroup
            });
            $location.search({startDate: $scope.date.startDate, endDate: $scope.date.endDate, Supplier: supplier,
                ProductGroup: productgroup});

            salesOnItemChart.$promise.then(function () {
                $scope.salesOnItemChart = salesOnItemChart;
                var x = [];
                x.push('x');
                x.push.apply(x, salesOnItemChart.xAxe.x);
                var y = [];
                var allSeries = salesOnItemChart.yAxe;
                var allAverages = salesOnItemChart.average;
                var aSerie = {};
                var totalPSamount = 0;
                for (var key in allSeries) {
                    if (allSeries.hasOwnProperty(key)) {
                        var seriesWithNameAndValues = [];
                        seriesWithNameAndValues.push(key);
                        seriesWithNameAndValues.push(allAverages[key]);
                        var currentSeriesValues = allSeries[key];

                        for (var a in currentSeriesValues) {
                          if(currentSeriesValues.hasOwnProperty(a)){
                            var temp = currentSeriesValues[a];
                            aSerie['amount' + a] = temp.amount;
                            aSerie['name' + a] = temp.name;
                            aSerie['availability' + a] = temp.availability;
                            aSerie['currentStockAmount' + a] = temp.currentStockAmount;
                            totalPSamount = totalPSamount + temp.amount;
                          }
                        }

                        seriesWithNameAndValues.push.apply(
                                seriesWithNameAndValues,
                                currentSeriesValues
                                );
                        y.push(seriesWithNameAndValues);
                    }
                }
                $scope.dataamount = totalPSamount;
                var columnDefs = ChartCommons.generateChartColumnsConfig(salesOnItemChart);
                $scope.columnDefs = columnDefs;
                $scope.y = y;
                var tableConfig = ChartCommons.generateChartSeries(salesOnItemChart);
                $log.info(tableConfig);
                $scope.myData = tableConfig.data;
                $scope.isExistingDataToExport = true;
                $log.info('generated config');

                $scope.gridOptions = {
                    data: $scope.myData,
                    enableHorizontalScrollbar: 2,
                    columnDefs: $scope.columnDefs,
                    enablePinning: true
                };

            });
            $log.info('end display');
        };

        $scope.exportExcel = function(){
            return  ExcelExport.export($scope.gridApi, $scope.date.startDate, $scope.date.endDate);
        };

        $scope.optionSupChange = function () {
            var domsupID = $scope.Supplier;
            if (domsupID.id > 0) {
                $scope.ProductGroup = $scope.prodGroupOptions[0];
                $scope.pgbuffer = false;
                $location.search({startDate: $scope.date.startDate, endDate: $scope.date.endDate, Supplier: $scope.Supplier.id});
            } else {
                if ($scope.ProductGroup.id === 0) {
                    $scope.pgbuffer = false;
                }
            }
        };

        $scope.optionpgChange = function () {
            var dompgID = $scope.ProductGroup;
            if (dompgID.id > 0) {
                $scope.Supplier = $scope.supplierOptions[0];
                $scope.pgbuffer = true;
                $location.search({startDate: $scope.date.startDate, endDate: $scope.date.endDate, ProductGroup: $scope.ProductGroup.id});
            } else {
                $scope.pgbuffer = false;
            }
        };
        $scope.$on('$viewContentLoaded', function () {
            var param = $location.search();
            $scope.date.startDate = param.startDate;
            $scope.date.endDate = param.endDate;
            if (param.startDate && param.endDate) {
                $scope.displayChart();
            }
        });

    }

})();
