(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OttoProducts2Products', OttoProducts2Products);

    OttoProducts2Products.$inject = ['$resource', 'DateUtils'];

    function OttoProducts2Products ($resource, DateUtils) {
        var resourceUrl =  'importer/' + 'api/otto-products-2-products/:id';
        var getProductsResourceUrl =  'importer/api/products';
        var getProductByResourceUrl =  'importer/api/products/:id';
        var bulkUpdateResourceUrl = 'importer/' + 'api/otto-products-2-products/bulk-update';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.priceActiveSince = DateUtils.convertLocalDateFromServer(data.priceActiveSince);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.priceActiveSince = DateUtils.convertLocalDateToServer(copy.priceActiveSince);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.priceActiveSince = DateUtils.convertLocalDateToServer(copy.priceActiveSince);
                    return angular.toJson(copy);
                }
            },
            'getAllProducts': {
                method: 'GET', 
                url: getProductsResourceUrl,
                isArray: true,
                response: function (data) {
                    return angular.toJson(data);
                }
            },
            'getProductById': {
                method: 'GET', 
                url: getProductByResourceUrl,
                response: function (data) {
                    return angular.toJson(data);
                }
            },
            'bulkUpdate': {
                method: 'PUT',
                url: bulkUpdateResourceUrl,
                response: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
