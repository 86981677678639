(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$log', '$timeout', '$scope', '$uibModalInstance', 'AlertService', 'entity', 'pcProduct', 'pcProductGroup'];

    function ProductDialogController ($log, $timeout, $scope, $uibModalInstance, AlertService, entity, Product, ProductGroup) {
        var vm = this;

        vm.product = entity;
        vm.productGroups = ProductGroup.query();
        vm.clear = clear;
        vm.save = save;
        vm.close = close;
        vm.offerIdToClone;
        vm.isProductExisting = isProductExisting;
        vm.showTechnicsGroup = false;
        vm.technicsGroupConfig = technicsGroupConfig;
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            technicsGroupConfig();
        });

        vm.tinymceOptions = {
            relative_urls : false,
            remove_script_host : false,
            menubar : false,
            statusbar: false,
            toolbar: "removeformat",
            plugins: "paste",
            paste_remove_styles_if_webkit: true,
            height : 300
        };
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function isProductExisting(offerIdToClone){
            Product.getByOfferId({offerId: offerIdToClone},function(result){
                vm.productFound = true;
                vm.product.title = result.title;
                vm.product.description = result.description;
                vm.product.hint = result.hint;
                vm.product.recommendation = result.recommendation;
                vm.product.productGroup = result.productGroup;
                vm.product.technicsGroup = result.technicsGroup;
            }, function(){
                vm.productFound = false;
            });
        }
        
        function save () {
            vm.isSaving = true;
            if(vm.product.isCreateClone){
                Product.createFromClone(vm.product, onSaveSuccess, onSaveError);
            }else if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gatewayApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function close(){
            $uibModalInstance.close();
        }
        
        function technicsGroupConfig(){
            if(vm.product.productGroup){
                vm.technicsGroups = vm.product.productGroup.technicsGroups;
                if(vm.technicsGroups.length > 0){
                    if(vm.technicsGroups.length > 1){
                        vm.showTechnicsGroup = true;
                        vm.technicsGroups = vm.product.productGroup.technicsGroups;
                    }else{
                        vm.product.technicsGroup = vm.technicsGroups[0];
                        vm.showTechnicsGroup = false;
                    }
                }else{
                    vm.product.technicsGroup = '';
                    AlertService.error("Warengruppe {{param}} falsch konfiguriert! Hat keine Technische Vorlage zugewisen!", {param:vm.product.productGroup.name});
                } 
            }
        }
    }
})();
