(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('pcTechnicsKey', TechnicsKey);

    TechnicsKey.$inject = ['$resource'];

    function TechnicsKey ($resource) {
        var resourceUrl =  'composer/api/technics-groups/:technicsGroupId/technics-keys/:id';

        return $resource(resourceUrl, {technicsGroupId: ''}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': {method:'DELETE', url:'composer/api/technics-keys/:id', params:{id:''}},
            'update': { method:'PUT', params:{technicsGroupId:''} },
            'save': { method:'POST', params:{technicsGroupId:''} },
            'getReferencedProducts': {method: 'GET', isArray: true, url: 'composer/api/technics-keys/:id/products'}
        });
    }
})();
