(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ImporterService', ImporterService);

    ImporterService.$inject = ['$resource'];

    function ImporterService($resource) {
        var resourceUrl = 'importer/api/importer/channel/:channel/availability';
        var ottoApiImporterUploadUrl = '/importer/api/importer/otto/availability';
        var getAvailabilityConfigResourceUrl = 'importer/' + 'api/availability-configs';
        return {
            getAvailability: $resource(resourceUrl, {}, {
                'get': {
                    method: 'GET',
                    transformResponse: function (data, headers) {
                        var response = {};
                        var header = headers();
                        var contentDispositionHeader = header['content-disposition'].split(';');
                        var filename = contentDispositionHeader[1].split('=')[1];
                        response.data = data;
                        response.filename = filename;
                        return response;
                    }
                },
                'getLastUploadOttoAvailability': {
                    method: 'GET',
                    url: ottoApiImporterUploadUrl,
                    transformResponse: function (data, headers) {
                        data = angular.fromJson(data);
                        return data;
                    }

                },
                'getAllAvailabilityConfigs': {
                    method: 'GET', 
                    url: getAvailabilityConfigResourceUrl,
                    isArray: true,
                    response: function (data) {
                        return angular.toJson(data);
                    }
                },
            }),
            getLastUploadOttoAvailability: $resource(ottoApiImporterUploadUrl, {}, {
                'get': {
                    method: 'GET',
                    transformResponse: function (data, headers) {
                        return data;
                    }
                }
            })
        };

    }
})();
