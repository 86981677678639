(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('composer.technics', {
            parent: 'composer',
            url: '/product/{productId}/technics',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'productComposerApp.technics.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/productComposer/technics/technics.html',
                    controller: 'TechnicsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('technicsGroup');
                    $translatePartialLoader.addPart('productGroup');
                    $translatePartialLoader.addPart('technics');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('composer.technics.new', {
            parent: 'composer.technics',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/technics/technics-new.html',
                    controller: 'TechnicsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                value: null,
                                isHighlight: null,
                                displayOrder: null,
                                highlightText: null,
                                isVerified: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('composer.technics', null, { reload: 'composer.technics' });
                }, function() {
                    $state.go('composer.technics');
                });
            }]
        })
        .state('composer.technics.edit', {
            parent: 'composer.technics',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/technics/technics-dialog.html',
                    controller: 'TechnicsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Technics', function(Technics) {
                            return Technics.get({productId:$stateParams.productId, id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.technics', null, { reload: 'composer.technics' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.technics.delete', {
            parent: 'composer.technics',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/technics/technics-delete-dialog.html',
                    controller: 'TechnicsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Technics', function(Technics) {
                            return Technics.get({productId:$stateParams.productId, id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.technics', null, { reload: 'composer.technics' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composer.technics.change_technics_group', {
            parent: 'composer.technics', // zugeh. url: 'product/{productId}/technics' 
            url: '/change_technics_group',             
            params: {productId: null},            
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/productComposer/technics/technics-migration-dialog.html',
                    controller: 'TechnicsMigrationDialogController',
                    controllerAs: 'vm',
                    size: 'md',                    
                    resolve: {
                        product: ['pcProduct', function(Product){
                            return Product.get({id:$stateParams.productId});
                        }],
                        productGroups: ['pcProductGroup', function(ProductGroup){
                            return ProductGroup.query();
                        }],
                        technicsGroups: ['pcTechnicsGroup', function(TechnicsGroup){
                            return TechnicsGroup.query();
                        }]
                    }
                }).result.then(function() {
                    $state.go('composer.technics', null, { reload: 'composer.technics' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
