(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoicePositionController', InvoicePositionController);

    InvoicePositionController.$inject = ['$stateParams', 'InvoicePosition'];

    function InvoicePositionController($stateParams, InvoicePosition) {

        var vm = this;
        vm.customerId = $stateParams.customerId;
        vm.invoiceInfoId = $stateParams.invoiceInfoId;
        vm.invoicePositions = [];

        loadAll();

        function loadAll() {
            InvoicePosition.query({invoiceInfoId: $stateParams.invoiceInfoId}, function(result) {
                vm.invoicePositions = result;
                vm.searchQuery = null;
            });
        }
    }
})();
