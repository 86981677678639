(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('NormaProducts2Products', NormaProducts2Products);

    NormaProducts2Products.$inject = ['$resource'];

    function NormaProducts2Products ($resource) {
        var resourceUrl =  'importer/' + 'api/norma-products-2-products/:id';
        var getProductsResourceUrl =  'importer/api/products';
        var getProductByResourceUrl =  'importer/api/products/:id';
        var bulkUpdateResourceUrl = 'importer/' + 'api/norma-products-2-products/bulk-update';
        
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAllProducts': {
                method: 'GET', 
                url: getProductsResourceUrl,
                isArray: true,
                response: function (data) {
                    return angular.toJson(data);
                }
            },
            'getProductById': {
                method: 'GET', 
                url: getProductByResourceUrl,
                response: function (data) {
                    return angular.toJson(data);
                }
            },
            'bulkUpdate': {
                method: 'PUT',
                url: bulkUpdateResourceUrl,
                response: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
