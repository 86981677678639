(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('pcProductSourceCodeController',ProductSourceCodeController);

    ProductSourceCodeController.$inject = 
    ['$log', '$scope', '$stateParams', 'entity', 'pcProduct', '$timeout'];
    
   
    function ProductSourceCodeController
    ($log, $scope, $stateParams, entity, Product, $timeout) {
        var vm = this;
        vm.isCopied = false;
        
        Product.sourcecode({id:$stateParams.id}).$promise
            .then(function(response){
                vm.sourcecode = response.source;
            });
        
        
        vm.hideIsCopied = hideIsCopied;
        vm.showIsCopied = showIsCopied;
        vm.id = entity.id;
        vm.name = entity.offerId;
        
        var clipboard = new Clipboard('.btn');
        clipboard.on('success', function(e) {
            $log.info('Action:', e.action);
            $log.info('Trigger:', e.trigger);
            vm.showIsCopied();
            vm.hideIsCopied();
            e.clearSelection();
        });
        clipboard.on('error', function(e){
            $log.error(e);
        });
        
        function showIsCopied(){
            $scope.$apply(function(){
                $scope.vm.isCopied= true;
            });
        }
        function hideIsCopied(){
            $timeout(function() {
                $scope.$apply(function(){
                    $scope.vm.isCopied= false;
                });
            }, 1000);
        }
    }
})();
