(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-2-price', {
            parent: 'entity',
            url: '/customer/{customerId}/product-2-price',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.product2Price.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-2-price/product-2-prices.html',
                    controller: 'Product2PriceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product2Price');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                customer: ['$stateParams', 'Customer', function($stateParams, Customer){
                    return Customer.get({id: $stateParams.customerId}).$promise;
                }]
            }
        })
        .state('product-2-price.new', {
            parent: 'product-2-price',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-2-price/product-2-price-dialog.html',
                    controller: 'Product2PriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                chanel: null,
                                priceNetto: null,
                                priceBrutto: null,
                                priceActiveSince: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product-2-price', null, { reload: 'product-2-price' });
                }, function() {
                    $state.go('product-2-price');
                });
            }]
        })
        .state('product-2-price.edit', {
            parent: 'product-2-price',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-2-price/product-2-price-dialog.html',
                    controller: 'Product2PriceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product2Price', function(Product2Price) {
                            return Product2Price.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-2-price', null, { reload: 'product-2-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-2-price.delete', {
            parent: 'product-2-price',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-2-price/product-2-price-delete-dialog.html',
                    controller: 'Product2PriceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product2Price', function(Product2Price) {
                            return Product2Price.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-2-price', null, { reload: 'product-2-price' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
