(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('dateRangePicker', dateRangePicker);

    dateRangePicker.$inject = ['$location'];

    function dateRangePicker(location){
        var directive = {

            restrict: 'A',
            scope: {date: '=',
                startDate: '='},
            link: function (scope, element, attrs) {
                scope.$location = location;
                var dStartDate = scope.$parent.startDate;
                var dEndDate = scope.$parent.endDate;

                if (angular.isUndefined(dStartDate) || angular.isUndefined(dEndDate)) {
                    dEndDate = moment();
                    dStartDate = moment().subtract(29, 'days');
                }
                scope.$parent.date.startDate = dStartDate.format('YYYY-MM-DD');
                scope.$parent.date.endDate = dEndDate.format('YYYY-MM-DD');

                scope.$watch('$location.search()', function (query) {
                  if (angular.isUndefined(query.startDate)|| angular.isUndefined(query.endDate)) {
                      dStartDate = moment().subtract(29, 'days');
                      dEndDate = moment();
                      scope.$parent.date.startDate = dStartDate.format('YYYY-MM-DD');
                      scope.$parent.date.endDate = dEndDate.format('YYYY-MM-DD');
                  } else {
                      dStartDate = moment(query.startDate);
                      dEndDate = moment(query.endDate);
                  }
                  $('#reportrange span').html(
                              dStartDate.format('MMMM D, YYYY')
                              + ' - ' + dEndDate.format('MMMM D, YYYY'));
              });

                element.daterangepicker({
                  format: 'MM/DD/YYYY',
                  startDate: dStartDate,
                  endDate: dEndDate,
                  minDate: '01/01/2012',
                  maxDate: '12/31/2030',
                  showDropdowns: true,
                  showWeekNumbers: true,
                  timePicker: false,
                  timePickerIncrement: 1,
                  timePicker12Hour: true,
                  ranges: {
                      'Heute': [moment(), moment()],
                      'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                      'Letzten 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Letzten 30 Days': [moment().subtract(29, 'days'), moment()],
                      'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
                      'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                  },
                  opens: 'left',
                  drops: 'down',
                  buttonClasses: ['btn', 'btn-sm'],
                  applyClass: 'btn-primary',
                  cancelClass: 'btn-default',
                  separator: ' to ',
                  locale: {
                      applyLabel: 'Auswählen',
                      cancelLabel: 'Abbrechen',
                      fromLabel: 'Von',
                      toLabel: 'Bis',
                      customRangeLabel: 'Frei auswählen',
                      daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                      monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'July', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                      firstDay: 1
                  }
              }, function (start, end, label) {
                  $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                  scope.$parent.date.startDate = start.format('YYYY-MM-DD');
                  scope.$parent.date.endDate = end.format('YYYY-MM-DD');
          }
                  );
          }

        };
        return directive;
    }

})();
