(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('Product2PriceDeleteController',Product2PriceDeleteController);

    Product2PriceDeleteController.$inject = ['$uibModalInstance', 'entity', 'Product2Price'];

    function Product2PriceDeleteController($uibModalInstance, entity, Product2Price) {
        var vm = this;

        vm.product2Price = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Product2Price.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
