(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('norma-products-2-products', {
            parent: 'entity',
            url: '/norma-products-2-products',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.normaProducts2Products.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products.html',
                    controller: 'NormaProducts2ProductsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('normaProducts2Products');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('norma-products-2-products-detail', {
            parent: 'norma-products-2-products',
            url: '/norma-products-2-products/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gatewayApp.normaProducts2Products.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products-detail.html',
                    controller: 'NormaProducts2ProductsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('normaProducts2Products');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'NormaProducts2Products', function($stateParams, NormaProducts2Products) {
                    return NormaProducts2Products.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'norma-products-2-products',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('norma-products-2-products-detail.edit', {
            parent: 'norma-products-2-products-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products-dialog.html',
                    controller: 'NormaProducts2ProductsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['NormaProducts2Products', function(NormaProducts2Products) {
                            return NormaProducts2Products.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('norma-products-2-products.new', {
            parent: 'norma-products-2-products',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products-dialog.html',
                    controller: 'NormaProducts2ProductsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                exported4Availability: true,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('norma-products-2-products', null, { reload: 'norma-products-2-products' });
                }, function() {
                    $state.go('norma-products-2-products');
                });
            }]
        })
        .state('norma-products-2-products.edit', {
            parent: 'norma-products-2-products',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products-dialog.html',
                    controller: 'NormaProducts2ProductsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['NormaProducts2Products', function(NormaProducts2Products) {
                            return NormaProducts2Products.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('norma-products-2-products', null, { reload: 'norma-products-2-products' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('norma-products-2-products.delete', {
            parent: 'norma-products-2-products',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/norma-products-2-products/norma-products-2-products-delete-dialog.html',
                    controller: 'NormaProducts2ProductsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['NormaProducts2Products', function(NormaProducts2Products) {
                            return NormaProducts2Products.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('norma-products-2-products', null, { reload: 'norma-products-2-products' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
