'use strict';

angular.module('gatewayApp')
            .factory('SalesAverage', SalesAverage);

SalesAverage.$inject = ['$resource'];

function SalesAverage($resource) {

    return {
        salesAverage: $resource('bi/api/charts/:verb', {
            verb: 'salesonitemchartDW',
            startDate: '@startDate',
            endDate: '@endDate',
            Supplier: '@Supplier',
            ProductGroup: '@ProductGroup'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        })

    };
}
