(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HellwegProducts2ProductsDetailController', HellwegProducts2ProductsDetailController);

    HellwegProducts2ProductsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HellwegProducts2Products'];

    function HellwegProducts2ProductsDetailController($scope, $rootScope, $stateParams, previousState, entity, HellwegProducts2Products) {
        var vm = this;

        vm.hellwegProducts2Products = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:hellwegProducts2ProductsUpdate', function(event, result) {
            vm.hellwegProducts2Products = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
