(function(){

    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
      .state('importer', {
          url: '/importer',
          parent: 'entity',
          data: {
              authorities: ['ROLE_USER'],
              pageTitle: 'gatewayApp.customer.home.title'
          },
          views: {
              'content@': {
                  templateUrl: 'app/entities/importer/importer.html',
                  controller: 'ImporterController',
                  controllerAs: 'vm'
              }
          },
          resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('customer');
                  $translatePartialLoader.addPart('global');
                  $translatePartialLoader.addPart('importer');
                  return $translate.refresh();
              }]
          }
      })
      .state('importer.availability', {
          url: '/availability',
          parent: 'importer',
          data: {
              authorities: ['ROLE_USER'],
              pageTitle: 'gatewayApp.customer.home.title'
          },
          views: {
              'content@': {
                  templateUrl: 'app/entities/importer/importer.html',
                  controller: 'ImporterController',
                  controllerAs: 'vm'
              }
          }
      });
    }
})();
