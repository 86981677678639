(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrderPositionDeleteController',OrderPositionDeleteController);

    OrderPositionDeleteController.$inject = ['$uibModalInstance', 'entity', 'OrderPosition'];

    function OrderPositionDeleteController($uibModalInstance, entity, OrderPosition) {
        var vm = this;

        vm.orderPosition = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OrderPosition.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
