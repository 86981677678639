'use strict';

angular.module('gatewayApp')
        .factory('SalesOnCountry', SalesOnCountry);

SalesOnCountry.$inject = ['$resource'];

function SalesOnCountry($resource) {
    return {
        salesOnDateChart: $resource('bi/api/charts/:verb', {
            verb: 'salesoncountrychart',
            startDate: '@startDate',
            endDate: '@endDate'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        })
    };
}
