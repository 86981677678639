(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LidlProducts2ProductsDetailController', LidlProducts2ProductsDetailController);

    LidlProducts2ProductsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LidlProducts2Products', 'Product'];

    function LidlProducts2ProductsDetailController($scope, $rootScope, $stateParams, previousState, entity, LidlProducts2Products, Product) {
        var vm = this;

        vm.lidlProducts2Products = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gatewayApp:lidlProducts2ProductsUpdate', function(event, result) {
            vm.lidlProducts2Products = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
