(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DeliveryHistoryController', DeliveryHistoryController);

    DeliveryHistoryController.$inject = ['DeliveryHistory'];

    function DeliveryHistoryController(DeliveryHistory) {

        var vm = this;

        vm.deliveryHistories = [];

        loadAll();

        function loadAll() {
            DeliveryHistory.query(function(result) {
                vm.deliveryHistories = result;
                vm.searchQuery = null;
            });
        }
    }
})();
