(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GenericExcelFileController', GenericExcelFileController);

    GenericExcelFileController.$inject = ['GenericExcelFile', 'AlertService', '$scope', '$http'];

    function GenericExcelFileController(GenericExcelFile, AlertService, $scope, $http) {
        var vm = this;
        vm.excelFiles = [];
        loadAll();
        function loadAll() {
            GenericExcelFile.query(function (result) {
                vm.excelFiles = result;
                vm.searchQuery = null;
            });
        }

        $scope.uploadGenericFile = function (event) {
            if (vm.channelName !== undefined) {
                if (!event[0].files.length)
                    return null;
                var formData = new FormData();
                formData.append('uploadFile', event[0].files[0]);
                $http({
                    method: 'POST',
                    url: 'importergeneric/api/excel-files-extended/upload-file/' + vm.channelName,
                    headers: { 'Content-Type': undefined },
                    data: formData,
                }).success(function (response) {
                    AlertService.success("Excel file imported successfully.");
                    loadAll();
                }).error(function (response) {
                    AlertService.error(response.detail);
                });
            } else {
                console.log("inside");
                AlertService.info("Please select channel name");
            }
        }
    }


})();
