(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('InvoiceEdiController', InvoiceEdiController);

    InvoiceEdiController.$inject = ['DataUtils', 'InvoiceEdi'];

    function InvoiceEdiController(DataUtils, InvoiceEdi) {

        var vm = this;

        vm.invoiceEdis = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            InvoiceEdi.query(function(result) {
                vm.invoiceEdis = result;
                vm.searchQuery = null;
            });
        }
    }
})();
