(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('pcPicture', Picture);

    Picture.$inject = ['$resource'];

    function Picture ($resource) {
        var resourceUrl =  'composer/api/products/:productId/pictures/:id';

        return $resource(resourceUrl, {productId: '', id: '', picturesToDelete: ''}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT', params:{productId: '', id: ''} },
            'save': { method:'POST', params:{productId: '', id: ''}},
            'getavailablepic': {method: 'POST', url: '/composer/api/pictrures/crawler', isArray: true},
            'createpic': {method: 'POST', params:{productId: ''}, url: '/composer/api/products/:productId/pictures/create'},
            'delete_selection': {method: 'DELETE', params:{picturesToDelete: ''},url: '/composer/api/pictures'}
        });
    }
})();
