(function () {
    'use strict';

    angular.module('gatewayApp')
            .controller(
                    'SalesOnCountryController', SalesOnCountryController);

    SalesOnCountryController.$inject = ['$scope', '$log', 'SalesOnCountry'];
    function SalesOnCountryController($scope, $log, SalesOnCountry) {

        $scope.date = {
            startDate: null,
            endDate: null
        };
        $scope.displayChart2 = function () {
            $log.info('start diyplay sales on country');
            $scope.date.startDate = $('#reportrange').data().daterangepicker.startDate
                    .format('YYYY-MM-DD');
            $scope.date.endDate = $('#reportrange').data().daterangepicker.endDate
                    .format('YYYY-MM-DD');
            var bestSalesChart = SalesOnCountry.salesOnDateChart.get({
                startDate: $scope.date.startDate,
                endDate: $scope.date.endDate
            });
            var Wlocation = window.location.href;
            if (Wlocation.indexOf('?') > -1) {
                Wlocation = Wlocation.substr(0, Wlocation.indexOf('?'));
            }
            window.location = Wlocation + '?startDate=' + $scope.date.startDate + '&endDate=' + $scope.date.endDate;
            bestSalesChart.$promise.then(function () {
                $scope.bestSalesChart = bestSalesChart;
                var x = [];
                x.push('x');
                x.push.apply(x, bestSalesChart.xAxe.x);
                var y = [];
                var vTableData = [];
                var pieData = [];
                var allSeries = bestSalesChart.yAxe;
                $log.info(bestSalesChart);
                for (var key in allSeries) {
                    if (allSeries.hasOwnProperty(key)) {
                        var seriesWithNameAndValues = [];
                        var seriesWithNameAndValuesForTable = [];
                        seriesWithNameAndValues.push(key);
                        seriesWithNameAndValuesForTable.push(key);
                        var seriesValues = allSeries[key];
                        var seriesTableValues = allSeries[key];
                        var vtemptotal = 0;
                        for (var dval in seriesValues) {
                            if(seriesValues.hasOwnProperty(dval)){
                                vtemptotal = vtemptotal + seriesValues[dval];
                            }
                        }
                        pieData.push(seriesWithNameAndValues);
                        pieData.push(vtemptotal);
                        seriesWithNameAndValues.push.apply(
                                seriesWithNameAndValues,
                                seriesValues);
                        y.push(seriesWithNameAndValues);
                        seriesTableValues.unshift(vtemptotal);
                        seriesWithNameAndValuesForTable.push.apply(
                                seriesWithNameAndValuesForTable,
                                seriesTableValues);
                        vTableData.push(seriesWithNameAndValuesForTable);
                    }
                }
                var chartColumns = [];
                var piechartColumns = [];
                chartColumns.push(x);
                $scope.y = y;
                $scope.vTableData = vTableData;
                for (var int = 0; int < y.length; int++) {
                    chartColumns.push(y[int]);
                }
                for (int = 0; int < pieData.length; int++) {
                    piechartColumns.push(pieData[int]);
                }
                var chartConfig = {
                    bindto: '#chart',
                    data: {
                        labels: true,
                        x: 'x',
                        columns: chartColumns,
                        type: 'line'
                    },
                    subchart: {
                        show: true
                    },
                    axis: {
                        x: {
                            type: 'timeseries',
                            tick: {
                                format: '%Y-%m-%d'
                            }
                        }
                    }
                };
                c3.generate(chartConfig);
            });
        };
    }


})();
